<template>
    <div id="intro" class="bg-blue-800">
        <header class="fixed inset-x-0 top-0 z-50 bg-blue-800">
            <nav class="flex items-center justify-between p-1 lg:px-8" aria-label="Global">
                <div class="flex lg:flex-1">
                    <a href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only">Galego</span>
                        <img class="h-12 w-auto" src="@/assets/images/foto_site.png" alt="" />
                    </a>
                </div>
                <div class="flex lg:hidden text-white">
                    <button type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        @click="mobileMenuOpen = true">
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <a v-for="item in navigation" :key="item.name" :href="item.href"
                        class=" font-semibold leading-6 text-blue-800 bg-yellow-500 hover:bg-yellow-800 rounded-lg px-8 py-2">
                        {{ item.name }}
                    </a>
                </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="https://psd.org.br" class="text-white font-semibold leading-6 text-gray-900">Partido <span
                            aria-hidden="true">&rarr;</span></a>
                </div>
            </nav>
            <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50" />
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">Your Company</span>
                            <img class="h-8 w-auto" src="@/assets/images/alho.png" alt="" />
                        </a>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <a v-for="item in navigation" :key="item.name" :href="item.href"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{
                                        item.name }}</a>
                            </div>
                            <div class="py-6">
                                <a href="https://psd.org.br"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Partido</a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
        <div class="relative isolate px-6 pt-14 lg:px-8">
            <div class="mx-auto max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-4xl py-20 sm:py-28 lg:py-40">
                <swiper :spaceBetween="30" :slidesPerView="1" loop="true" :autoplay="{ delay: 2000 }"
                    :pagination="{ clickable: true }" navigation class="mySwiper">
                    <swiper-slide v-for="(image, index) in images" :key="index">
                        <img :src="require(`@/assets/images/carrossel/${image}`)" :alt="`Imagem ${index + 1}`"
                            class="w-full max-w-none rounded-xl object-cover" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>

    <div id="minha-historia"
        class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div
            class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div
                class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div class="lg:pr-4">
                    <div class="lg:max-w-lg">
                        <p class="text-base font-semibold leading-7 text-blue-800">Minha História</p>
                        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">PORQUE ESTOU AQUI!
                        </h1>
                        <p class="mt-4 text-x leading-8 text-gray-700">Minha jornada começou há 26 anos, quando escolhi
                            Pau dos
                            Ferros como meu lar. Desde então, meu propósito é claro: transformar vidas! Paraibano de
                            nascença,
                            Pau-ferrense de coração! Minha história se confunde com a história dessa cidade que tanto
                            amo.</p>
                        <p class="mt-4 text-x leading-8 text-gray-700">De feirante a vereador, cada passo foi dado com
                            dedicação e
                            muito amor pelo povo. Trabalhei desde cedo e
                            aprendi que responsabilidade, determinação e empatia são as chaves para fazer a diferença.
                            Essas lições me
                            trouxeram até aqui, com a certeza de que o trabalho nunca parar.</p>
                        <p class="mt-4 text-x leading-8 text-gray-700">Sempre me inspirei em fazer o melhor pela
                            política, porque
                            desde que cheguei em Pau dos
                            Ferros, vi de perto as necessidades do nosso povo. Tive o privilégio de acompanhar o
                            trabalho de quem
                            admiro profundamente: meu grande amigo, Dr. Nilton Figueiredo.</p>
                        <p class="mt-4 text-x leading-8 text-gray-700">Estar na política é desafiador, mas é também onde
                            encontro
                            meu propósito. Quero fazer mais,
                            quero transformar, quero melhorar a vida de cada pau-ferrense. Juntos, podemos mais! 🤝</p>
                    </div>
                </div>
            </div>
            <div
                class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div class="lg:pr-4">
                    <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                    </div>
                </div>
            </div>
            <div
                class="-ml-6 -mt-6 p-6 sm:-ml-8 sm:-mt-8 sm:p-8 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img class="w-full sm:w-[35rem] md:w-[40rem] lg:w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                    src="@/assets/images/gEscola.jpg" alt="Imagem de escola" />
            </div>
        </div>
        <div id="propostas" class="bg-white py-24 sm:py-32">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">Para melhorar Pau dos Ferros!</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Minhas Propostas</p>
                    <!-- <p class="mt-6 text-lg leading-8 text-gray-600">Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum pulvinar et feugiat blandit at. In mi viverra elit nunc.</p> -->
                    <div class="max-w-2xl mx-auto mt-8">
                        <div v-for="(faq, index) in faqs" :key="index" class="border-b border-gray-300">
                            <div @click="toggleFAQ(index)"
                                class="py-4 flex justify-between items-center cursor-pointer">
                                <h3 class="text-lg font-semibold"
                                    :class="{ 'text-black': activeIndex === index, 'text-gray-600': activeIndex !== index }">
                                    {{ faq.question }}
                                </h3>
                                <span class="text-2xl">
                                    <template v-if="activeIndex === index">−</template>
                                    <template v-else>+</template>
                                </span>
                            </div>
                            <div v-if="activeIndex === index" class="pb-4">
                                <p class="text-gray-500 text-left" v-html="faq.answer"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white py-16">
            <div class="text-center">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Ouça agora o Paredão do Galeguinho!
                </h2>
                <p class="mt-4 text-lg leading-8 text-gray-600">
                    Conheça e baixe nossas músicas!
                </p>
                <a href="/jingles"
                    class="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-8 py-3 text-base font-medium text-blue-800 hover:bg-yellow-600 mt-6">
                    Acessar o Paredão do Galeguinho!
                </a>
            </div>
        </div>
    </div>
    <footer id="contato" class="bg-blue-800 text-white py-10"
        style="background-image: url('@/assets/images/mão.png'); background-repeat: repeat; background-size: contain;">
        <div class="container mx-auto px-4">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-center lg:text-left">
                <!-- Coluna de Elementos -->
                <div>
                    <h3 class="text-lg font-semibold mb-4">Elementos</h3>
                    <ul class="space-y-2">
                        <li><a href="#intro" class="hover:text-pink-500">Início</a></li>
                        <li><a href="#minha-historia" class="hover:text-pink-500">Minha História</a></li>
                        <li><a href="#propostas" class="hover:text-pink-500">Propostas</a></li>
                        <li><a href="#contato" class="hover:text-pink-500">Contato</a></li>
                    </ul>
                </div>
                <div class="sm:col-span-2 lg:col-span-1">
                    <h3 class="text-lg font-semibold mb-4">Contato</h3>
                    <ul class="space-y-2">
                        <li class="flex items-center justify-center lg:justify-start">
                            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2 h-8 w-8 sm:h-10 sm:w-10" />
                            <a href="https://wa.me/5584997050563" class="hover:text-pink-500">WhatsApp</a>
                        </li>
                        <li class="flex items-center justify-center lg:justify-start">
                            <font-awesome-icon :icon="['fas', 'envelope']" class="mr-2 h-8 w-8 sm:h-10 sm:w-10" />
                            <a href="mailto:vereador@galegodoalhorn.online" class="hover:text-pink-500">E-mail:
                                vereador@galegodoalhorn.online</a>
                        </li>
                    </ul>
                </div>
                <div class="sm:col-span-2 lg:col-span-1">
                    <h3 class="text-lg font-semibold mb-4">Redes Sociais</h3>
                    <ul class="flex justify-center lg:justify-start space-x-6">
                        <li>
                            <a href="https://www.instagram.com/galegodoalho_?igsh=NHhuMWI1MGl2bHN0"
                                class="hover:text-pink-500 flex items-center">
                                <font-awesome-icon :icon="['fab', 'instagram']" class="mr-2 h-8 w-8 sm:h-10 sm:w-10" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/galego.doalho/"
                                class="hover:text-pink-500 flex items-center">
                                <font-awesome-icon :icon="['fab', 'facebook']" class="mr-2 h-8 w-8 sm:h-10 sm:w-10" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.threads.net/@galegodoalho_"
                                class="hover:text-pink-500 flex items-center">
                                <font-awesome-icon :icon="['fab', 'threads']" class="mr-2 h-8 w-8 sm:h-10 sm:w-10" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-center lg:justify-start space-x-4">
                    <h1 class="text-center text-lg">CLIQUE AQUI! ➡️</h1>
                    <a href="#" @click.prevent="playAudio" class="-m-1.5 p-1.5 cursor-pointer">
                        <span class="sr-only">Your Company</span>
                        <img class="h-42 w-auto" src="@/assets/images/galelgofog.png" alt="Logo" />
                    </a>
                    <audio ref="audio"></audio>
                </div>
            </div>
            <a href="https://www.instagram.com/devvictorgurgel?igsh=ZWo4YmlwNXpwcjdt">
                <h4 class="text-center text-sm mt-8 text-gray-300">2024 © Todos os direitos reservados - Galego do Alho.
                    CNPJ:56.508.598/0001-89 - ELEIÇÃO 2024 JOSÉ ALVES BENTO VEREADOR</h4>
            </a>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInstagram, faFacebook, faThreads, faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Importar ícone do WhatsApp da biblioteca de ícones de marcas
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Autoplay, Pagination, Navigation]);

library.add(faInstagram, faFacebook, faThreads, faWhatsapp, faEnvelope);

const audioSrc = require('@/assets/music/55123_Galego_do_Alho.mp3');

const playAudio = () => {
    const audio = document.querySelector('audio');
    if (audio) {
        audio.src = audioSrc;
        audio.play();
    }
};

const mobileMenuOpen = ref(false);

const navigation = [
    { name: 'Início', href: '#intro' },
    { name: 'Minha História', href: '#minha-historia' },
    { name: 'Propostas', href: '#propostas' },
    { name: 'Paredão do Galeguinho', href: '/jingles' },
    { name: 'Contato', href: '#contato' }
];

const images = ref([
    'galego.png',
    '1.1.png',
    '2.png',
    '3.png',
    '4.png',
]);

</script>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            activeIndex: null,
            faqs: [
                {
                    question: "1. Saúde",
                    answer: `• Sugerir um centro de atendimento psicológico gratuito;<br><br>
  • Propor a ampliação do horário de funcionamento para 24h de pelo menos uma unidade básica de saúde;<br><br>
  • Propor a aquisição de novas ambulâncias para o município;<br><br>
  Propor implantação de um sistema online de marcação de consultas e exames especializados, facilitando o agendamento e reduzindo o tempo de espera;<br><br>
  • Propor para introduzir a telemedicina para facilitar o acesso a consultas com especialistas, principalmente para moradores das áreas rurais ou com dificuldades de deslocamento, ampliando o acesso a cuidados especializados.`
                },
                {
                    question: "2. Educação",
                    answer: `• Propor a construção de novas creches em bairros com maior demanda; <br><br>
  • Propor projetos de reforço escolar para alunos com dificuldades de aprendizagem; <br><br>
  • Propor a Biblioteca Itinerante que percorra bairros e comunidades rurais, levando livros, atividades de leitura e recursos educativos para crianças e adultos, incentivando a leitura e o aprendizado; <br><br>
  • Propor a ampliação da modernização das escolas com a inclusão de laboratórios de informática e a disponibilização de acesso à internet de qualidade, além de promover o uso de tecnologias educacionais inovadoras nas salas de aula; <br><br>
  • Propor a criação de centro de apoio ao estudante, para oferecer orientação educacional, psicológica e vocacional aos estudantes e suas famílias, ajudando na tomada de decisões sobre o futuro acadêmico e profissional.`
                },
                {
                    question: "3. Infraestrutura",
                    answer: `• Propor a pavimentação de ruas em bairros ainda não atendidos; <br><br>
  • Propor a construção de ciclovias e faixas exclusivas para pedestres em bairros ainda não atendidos; <br><br>
  • Propor a construção do Teatro Municipal;  <br><br>
  • Propor a construção do Memorial da História da nossa cidade; <br><br>
  • Propor a construção de centros de convivência dedicados aos idosos, oferecendo espaços para atividades recreativas, culturais e de socialização, melhorando a qualidade de vida dessa população.`
                },
                {
                    question: "4. Segurança",
                    answer: `• Propor a criação de uma Guarda Municipal com foco na proteção do patrimônio público e na colaboração com as polícias estaduais para melhorar a segurança local; <br><br>
  • Propor a ampliação do sistema de videomonitoramento em pontos estratégicos da cidade;  <br><br>
  • Propor cursos gratuitos de primeiros socorros para a comunidade, capacitando os cidadãos a agirem em situações de emergência, enquanto aguardam a chegada dos serviços de emergência; <br><br>
  • Propor ampliação da substituição das luzes, por luzes de LED; <br><br>
  • Propor estabelecer parcerias com a Polícia Militar, criando uma equipe dedicada à segurança nas escolas, que inclua patrulhas nos horários de entrada e saída, além de programas educacionais de prevenção ao bullying, ao uso de drogas e à violência.`
                },
                {
                    question: "5. Meio Ambiente e Desenvolvimento Rural",
                    answer: `• Propor a criação de um parque ecológico: Área de preservação ambiental com espaço para lazer e atividades educativas; <br><br>
  • Propor a inclusão da educação ambiental para escolas municipais, focando em temas como reciclagem, conservação da água e mudanças climáticas. Promover atividades práticas como plantio de árvores e criação de hortas escolares; <br><br>
  • Propor criar um selo municipal de qualidade para produtos orgânicos e agroecológicos produzidos na região, incentivando a produção limpa e sustentável, e criando canais de comercialização para esses produtos; <br><br>
  • Propor melhoria das estradas vicinais; <br><br>
  • Propor criar um banco de sementes e mudas de espécies nativas, promovendo a conservação da biodiversidade local e fornecendo material genético de qualidade para os agricultores interessados em reflorestamento e recuperação de áreas degradadas.`
                }
            ]
        };
    },
    methods: {
        toggleFAQ(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        }
    }
};
</script>
