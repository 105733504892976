import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './components/home.vue';
import JinglesView from './components/jingles.vue';


const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/jingles',
        name: 'jingles',
        component: JinglesView
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
