<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
      <router-link to="/jingles"></router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
