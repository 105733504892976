<template>
  <div class="bg-blue-800 min-h-screen py-10 px-4">
    <div class="max-w-4xl mx-auto">
      <h1 class="text-4xl font-bold text-center text-yellow-500 mb-8">Paredão do Galeguinho!</h1>
      <button @click="goHome"
        class="bg-yellow-500 text-blue-800 py-2 px-6 rounded-lg hover:bg-gray-600 mb-6 mx-auto block">
        Voltar
      </button>
      <div v-for="(jingle, index) in jingles" :key="index" class="bg-white shadow-md rounded-lg p-6 mb-6">
        <div class="flex items-center mb-4">
          <div class="flex-1">
            <h2 class="text-xl font-semibold text-blue-800">{{ jingle.name }}</h2>
            <audio controls class="w-full mt-2">
              <source :src="jingle.src" type="audio/mp3" />
              Seu navegador não suporta o elemento de áudio.
            </audio>
          </div>
        </div>
        <div class="flex justify-center mt-4">
          <a :href="jingle.src" download class="bg-green-500 text-white py-2 px-4 rounded-lg mr-2 hover:bg-green-600">
            Baixar
          </a>
          <a :href="getWhatsAppLink(jingle.src)" target="_blank" rel="noopener noreferrer"
            class="bg-yellow-500 text-blue-800 py-2 px-4 rounded-lg hover:bg-yellow-600">
            Compartilhar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'; 

export default {
  name: 'JinglesView',
  setup() {
    const router = useRouter(); // Acessar o router

    // Função para redirecionar para a página inicial
    const goHome = () => {
      router.push('/'); // Redirecionar para a rota inicial
    };

    return {
      goHome
    };
  },
  data() {
    return {
      jingles: [
        {
          name: '55123 - Vote Certo Uma Vez', 
          src: require('@/assets/music/55123_vote_certo_uma_vez.mp3')
        },
        {
          name: 'Axé do Galego do Alho', 
          src: require('@/assets/music/axe_do_galego.mp3')
        },
        {
          name: 'Frevo do Galego Alho', 
          src: require('@/assets/music/frevo_do_galego.mp3')
        },
        {
          name: '55123 - Galego do Alho', 
          src: require('@/assets/music/55123_Galego_do_Alho.mp3')
        },
        {
          name: 'Aiai é o Galego do Alho', 
          src: require('@/assets/music/Aiai_e_o_Galego_do_Alho.mp3.mp3')
        },
        {
          name: 'Forró do Galego do Alho', 
          src: require('@/assets/music/forro_do_galego.mp3')
        }
      ]
    };
  },
  methods: {
    getWhatsAppLink(fileLink) {
      const message = `Olha esse jingle incrível! Clique aqui para ouvir: ${window.location.origin + fileLink}`;
      const encodedMessage = encodeURIComponent(message);
      return `https://api.whatsapp.com/send?text=${encodedMessage}`;
    }
  }
};
</script>
